import {
	ApolloError,
	gql,
	QueryHookOptions,
	QueryResult,
	useLazyQuery,
	useMutation,
	useQuery,
} from '@apollo/client';
import {
	ArticleOutlined,
	BugReportOutlined,
	CheckCircleOutlined,
	CodeOutlined,
	ErrorOutlined,
	FolderOutlined,
	FolderZipOutlined,
	GraphicEqOutlined,
	ImageOutlined,
	InsertDriveFileOutlined,
	SlideshowOutlined,
	SvgIconComponent,
	SwapVerticalCircleOutlined,
	WatchLaterOutlined,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import {
	GET_FOLDER_INFO,
	GET_ROOT_FOLDER_CONTENTS,
} from 'components/pages/documents/hooks/folders/use-folder';
import { FILE_INFO_FIELDS } from 'components/pages/documents/utils/fragments.graphql';
import {
	CsvFileOutlined,
	ExcelFileOutlined,
	PdfFileOutlined,
	PowerPointFileOutlined,
	WordDocumentOutlined,
	XactimateFileOutlined,
} from 'components/ui/icons';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useToast } from 'components/ui/toast';
import {
	FileAccessLevel,
	FileInstanceCopyRequest,
	FileInstanceInformation,
	Mutation,
	MutationFileInstanceCopyArgs,
	Query,
	QueryGetFileInstanceDownloadUrlArgs,
	QueryGetUploadUrlArgs,
	UploadTokenResponse,
	VirusStatus,
} from 'middleware-types';
import { ComponentProps, createContext, ReactNode, useContext, useState } from 'react';
import { handleNoResponse, responseHasErrors } from './errors';
import PQueue from 'p-queue';

/** get icon for file extension */
interface ExtensionIconProps extends ComponentProps<SvgIconComponent> {
	filename: string;
}

export const ExtensionIcon = ({ filename, ...props }: ExtensionIconProps) => {
	const extension = filename.split('.').pop()?.toLowerCase();
	switch (extension) {
		// Image types
		case 'bmp':
		case 'png':
		case 'jpg':
		case 'jpeg':
		case 'heic':
		case 'gif':
		case 'tiff':
		case 'svg':
			return <ImageOutlined {...props} />;
		// Folder - Assuming usage context allows folder handling
		case 'folder':
			return <FolderOutlined {...props} />;
		// Code files
		case 'htm':
		case 'html':
		case 'map':
		case 'css':
			return <CodeOutlined {...props} />;
		// Audio files
		case 'wav':
		case 'aiff':
		case 'midi':
		case 'ra':
		case 'mp3':
			return <GraphicEqOutlined {...props} />;
		// Video files
		case 'mov':
		case 'avi':
		case 'qt':
		case 'mp4':
		case 'mkv':
			return <SlideshowOutlined {...props} />;
		// Text file
		case 'txt':
		case 'md':
		case 'json':
			return <ArticleOutlined {...props} />;
		// Compressed files
		case 'zip':
			return <FolderZipOutlined {...props} />;
		// Document files
		case 'doc':
		case 'docx':
			return <WordDocumentOutlined {...props} />;
		// Spreadsheet files
		case 'xls':
		case 'xlsx':
			return <ExcelFileOutlined {...props} />;
		// Presentation files
		case 'ppt':
		case 'pptx':
			return <PowerPointFileOutlined {...props} />;
		// CSV files
		case 'csv':
			return <CsvFileOutlined {...props} />;
		// PDF files
		case 'pdf':
			return <PdfFileOutlined {...props} />;
		// ESX files
		case 'esx':
			return <XactimateFileOutlined {...props} />;
		default:
			return <InsertDriveFileOutlined {...props} />;
	}
};

// get virus status chip
interface VirusStatusChipProps {
	virusStatus: VirusStatus | undefined;
}

export const VirusStatusChip = ({ virusStatus }: VirusStatusChipProps) => {
	switch (virusStatus) {
		case VirusStatus.Pending:
			return <Chip size="small" icon={<WatchLaterOutlined />} label="Pending" />;
		case VirusStatus.Clean:
			return (
				<Chip size="small" icon={<CheckCircleOutlined />} label="Clean" color="success" />
			);
		case VirusStatus.Infected:
			return (
				<Chip size="small" icon={<BugReportOutlined />} label="Infected" color="error" />
			);
		case VirusStatus.ScanFailed:
			return (
				<Chip size="small" icon={<ErrorOutlined />} label="Scan Failed" color="warning" />
			);
		case VirusStatus.TooLargeToScan:
			return (
				<Chip
					size="small"
					icon={<ErrorOutlined />}
					label="Too Large to Scan"
					color="warning"
				/>
			);
		default:
			return <Chip size="small" icon={<SwapVerticalCircleOutlined />} label="Pending Save" />;
	}
};

/** File upload state. */
export enum UploadState {
	Pending,
	Loading,
	Success,
	Error,
}

/** Document upload queue. Uploads one at a time. */
export const documentUploadQueue = new PQueue({ concurrency: 1 });

/** Message attachment upload queue. Uploads one at a time. */
export const messageAttachmentUploadQueue = new PQueue({ concurrency: 1 });

/** File part upload queue shared by all uploads. Uploads two at a time. */
const partUploadGlobalQueue = new PQueue({ concurrency: 2 });

/** Document file part upload priority.. */
export const DOCUMENT_UPLOAD_PRIORITY = 0;

/** Message attachment file part upload priority. */
export const MESSAGE_ATTACHMENT_UPLOAD_PRIORITY = 1;

/** Get the overall upload progress of the file from its parts' progresses. */
export const getUploadProgress = (progressList: number[]) =>
	(progressList.reduce((partialSum, value) => (partialSum ?? 0) + (value ?? 0), 0) /
		progressList.length) *
	100;

/** File upload props. */
export interface UploadProps {
	/** The file to upload. */
	file: File;
	/** The file access level specified, if any. */
	accessLevel?: FileAccessLevel | undefined;
	/** The Id of the existing file this is updating, if any. */
	updatesFileId?: string | undefined;
	/** Function to call after the upload URL has been fetched. Optional. */
	onUploadUrlFetched?: (uploadToken: UploadTokenResponse) => void;
	/** Function to call on upload progress. Optional. */
	onUploadProgress?: (e: AxiosProgressEvent, partNum: number, uploadToken?: string) => void;
	/** Function to call after file has finished uploading. Optional. */
	onSuccess?: (response?: UploadTokenResponse) => Promise<void>;
	/** Function to call on error. Optional. */
	onError?: (e: ApolloError) => void;
	/** Priority of this upload. Optional. Higher number means higher priority. */
	priority?: number;
}

export const useUpload = () => {
	const [getUploadUrl] = useGetUploadUrl();

	const upload = async (props: UploadProps) => {
		const {
			file,
			accessLevel,
			updatesFileId,
			onUploadUrlFetched,
			onUploadProgress,
			onSuccess,
			onError,
			priority,
		} = props;

		// Get the upload URL(s).
		const result = await getUploadUrl({
			fetchPolicy: 'network-only',
			variables: {
				fileName: file.name,
				fileSize: file.size,
				requestedAccessLevel: accessLevel,
				updatesFileId: updatesFileId,
			},
			onError,
		});

		const uploadTokenResponse = result?.data?.getUploadUrl;
		if (!uploadTokenResponse) return;

		// Handle any logic needed after getting the upload URL and before starting the upload.
		onUploadUrlFetched?.(uploadTokenResponse);

		// Upload the file.
		await uploadFile(file, uploadTokenResponse, priority, onUploadProgress);

		await onSuccess?.(uploadTokenResponse);

		return;
	};

	return upload;
};

/** get upload url */
const GET_UPLOAD_URL = gql`
	query GetUploadUrl(
		$fileName: String!
		$fileSize: Float!
		$clientReference: String
		$updatesFileId: String
		$requestedAccessLevel: FileAccessLevel
	) {
		getUploadUrl(
			fileName: $fileName
			fileSize: $fileSize
			clientReference: $clientReference
			updatesFileId: $updatesFileId
			requestedAccessLevel: $requestedAccessLevel
		) {
			clientReference
			blobUploadUrls
			expiresUtc
			fileUploadToken
			uploadId
			multipartUploadId
		}
	}
`;

const useGetUploadUrl = () => {
	return useLazyQuery<Pick<Query, 'getUploadUrl'>, QueryGetUploadUrlArgs>(GET_UPLOAD_URL);
};

/** upload file */
const uploadFile = async (
	file: File,
	uploadToken: UploadTokenResponse | undefined,
	priority: number | undefined,
	onUploadProgress?: (e: AxiosProgressEvent, partNum: number, uploadToken?: string) => void
): Promise<void | undefined> => {
	const isMultipart = uploadToken?.blobUploadUrls && uploadToken.blobUploadUrls.length > 1;

	if (!uploadToken || (isMultipart && !uploadToken.multipartUploadId)) return;

	// Upload all parts of a file. If this is not a multipart upload, there will only be one part.
	return await uploadFileParts(uploadToken.blobUploadUrls, file, priority, (e, partNum) =>
		onUploadProgress?.(e, partNum, uploadToken.fileUploadToken)
	);
};

const uploadFileParts = async (
	uploadUrls: string[],
	file: File,
	priority: number | undefined,
	onUploadProgress?: (e: AxiosProgressEvent, partNumber: number) => void
): Promise<void> => {
	// Controller for cancelling part uploads.
	const cancelController = new AbortController();

	const uploadPart = async (part: Blob, index: number) =>
		await retryPartWithDelay(
			() =>
				axios
					.put(uploadUrls[index], part, {
						headers: {
							'Content-Type': '', // Content-Type will invalidate the signed url signature so force it to be blank
						},
						onUploadProgress: (progressEvent) =>
							onUploadProgress?.(progressEvent, index + 1),
						signal: cancelController.signal,
					})
					.then((response) => {
						return response;
					}),
			part,
			index
		);

	// Stores part upload tasks for this file.
	const partUploadQueue = new PQueue();
	let partUploadQueueError: boolean | null = null;

	const partSize = Math.ceil(file.size / uploadUrls.length);
	for (let index = 0; index < uploadUrls.length; index++) {
		const start = index * partSize;
		const end = start + partSize;

		partUploadQueue
			.add(() =>
				partUploadGlobalQueue.add(() => uploadPart(file.slice(start, end), index), {
					priority: priority,
				})
			)
			.catch(() => {
				partUploadQueueError = true;
				cancelController.abort('File part failed after retry.');
			});
	}

	const idleResult = await partUploadQueue.onIdle().then(() => {
		if (partUploadQueueError === null) partUploadQueueError = false;
	});

	while (
		!cancelController.signal.aborted &&
		(idleResult === null || partUploadQueueError === null)
	)
		setTimeout(() => null, 50);

	if (partUploadQueueError == true) {
		throw partUploadQueueError;
	} else {
		return idleResult;
	}
};

// https://learnersbucket.com/examples/interview/retry-promises-n-number-of-times-in-javascript/
const retryPartWithDelay = async (
	fn: (chunk: Blob, index: number) => Promise<AxiosResponse<any, any> | void>,
	chunk: Blob,
	index: number,
	attemptsLeft = 3,
	interval = 50,
	finalErr = 'File part upload failed'
) => {
	try {
		return await fn(chunk, index);
	} catch (err) {
		if (attemptsLeft <= 1) {
			throw finalErr;
		}

		// delay the next call
		await new Promise<void>((resolve) => {
			setTimeout(() => resolve(), interval);
		});

		// recursively call the same func
		return retryPartWithDelay(fn, chunk, index, attemptsLeft - 1, interval, finalErr);
	}
};

// https://learnersbucket.com/examples/interview/retry-promises-n-number-of-times-in-javascript/
export const retryFileWithDelay = async (
	fn: () => Promise<void>,
	attemptsLeft = 3,
	interval = 50,
	finalErr = 'File upload failed'
) => {
	try {
		return await fn();
	} catch (err) {
		if (attemptsLeft <= 1) {
			throw err;
		}

		// delay the next call
		await new Promise<void>((resolve) => {
			setTimeout(() => resolve(), interval);
		});

		// recursively call the same func
		return retryFileWithDelay(fn, attemptsLeft - 1, interval, finalErr);
	}
};

/** get download url */
const GET_FILE_INSTANCE_DOWNLOAD_URL = gql`
	query GetFileInstanceDownloadUrl(
		$fileInstanceId: String!
		$sasUrlOnly: Boolean
		$inline: Boolean
	) {
		getFileInstanceDownloadUrl(
			fileInstanceId: $fileInstanceId
			sasUrlOnly: $sasUrlOnly
			inline: $inline
		) {
			downloadUrl
			expiresUtc
		}
	}
`;

const checkFileExpiration = (
	result: QueryResult<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const { data, loading, refetch } = result;
	if (loading) return;
	const expiresUtc = data?.getFileInstanceDownloadUrl.expiresUtc;
	if (!expiresUtc) return;
	const expiration = new Date(expiresUtc);
	if (new Date() > expiration) refetch();
};

export const useDownloadUrl = (
	options: QueryHookOptions<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const result = useQuery<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>(GET_FILE_INSTANCE_DOWNLOAD_URL, options);
	checkFileExpiration(result);
	return result;
};

export const useLazyDownloadUrl = (
	options: QueryHookOptions<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const result = useLazyQuery<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>(GET_FILE_INSTANCE_DOWNLOAD_URL, options);
	return result;
};

/** download file */
const downloadFile = (
	url: string,
	options: {
		inline?: boolean;
		fileName?: string;
		type?: string;
	}
) => {
	const link = document.createElement('a');
	link.href = url;
	if (!options.inline) link.download = options.fileName ?? '';
	if (options.type) link.type = options.type;
	link.target = '_blank';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFileObject = (file: File, inline?: boolean) => {
	// Create a Blob from the file with an explicitly set MIME type to ensure correct handling across browsers.
	const blob = new Blob([file], { type: file.type });
	const url = URL.createObjectURL(blob);

	let fileName = file.name;
	if (fileName.match('.')?.length === 1 && fileName[0] === '.')
		fileName = url.split('/').pop()?.substring(0, 8) + fileName;

	downloadFile(url, { inline, fileName, type: file.type });
	setTimeout(() => URL.revokeObjectURL(url), 0);
};

export const useDownloadFileInstance = () => {
	const toast = useToast();
	const { setDownloadCallback } = useDownloadConfirmationContext();
	const [getDownloadUrl] = useLazyDownloadUrl({ fetchPolicy: 'network-only' });

	const downloadFileInstance = (instance: FileInstanceInformation, inline?: boolean) => {
		// if the file is infected, show error and return
		if (instance.virusStatus === VirusStatus.Infected) {
			toast.push('The file you are trying to download has been corrupted.', {
				variant: 'error',
			});
			return;
		}

		const download = async () => {
			// get sas url
			const res = await getDownloadUrl({
				variables: {
					fileInstanceId: instance.id,
					sasUrlOnly: true,
					inline,
				},
			});
			const url = res.data?.getFileInstanceDownloadUrl.downloadUrl;
			if (res.error || !url) {
				toast.push('An error occurred while trying to download the file.', {
					variant: 'error',
				});
				return;
			}

			// prefetch to make sure the url is valid
			axios
				.get(url, { headers: { Range: 'bytes=0-0' } })
				.then((res) => {
					if (res.status !== 206) throw new Error();
					// if the initial call suceeded, we can finally download the file
					downloadFile(url, {
						inline,
						fileName: instance.asciiFileName,
					});
				})
				.catch(() =>
					toast.push(
						'The file you are trying to download is not currently available. If this error continues, please contact technical support.',
						{ variant: 'error' }
					)
				);
		};

		// if there was no virus scan, show a modal before downloading
		if (
			instance.virusStatus === VirusStatus.Pending ||
			instance.virusStatus === VirusStatus.TooLargeToScan ||
			instance.virusStatus === VirusStatus.ScanFailed
		) {
			setDownloadCallback(() => download);
		} else {
			download();
		}
	};

	return downloadFileInstance;
};

const COPY_FILE_INSTANCE = gql`
	${FILE_INFO_FIELDS}
	mutation fileInstanceCopy($fileInstanceId: String!, $request: FileInstanceCopyRequest!) {
		fileInstanceCopy(fileInstanceId: $fileInstanceId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const useFileInstanceCopy = (instanceId: string) => {
	const toast = useToast();

	const [_fileInstanceCopy, loading] = useMutation<
		Pick<Mutation, 'fileInstanceCopy'>,
		MutationFileInstanceCopyArgs
	>(COPY_FILE_INSTANCE);

	const copyFileInstance = async (request: FileInstanceCopyRequest) => {
		return await _fileInstanceCopy({
			variables: {
				fileInstanceId: instanceId,
				request: request,
			},
			refetchQueries: request.folderId ? [GET_FOLDER_INFO] : [GET_ROOT_FOLDER_CONTENTS],
			awaitRefetchQueries: true,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully copied file.', {
					variant: 'success',
				});
				return true;
			})
			.catch((e) => {
				console.log(JSON.stringify(e));
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		copyFileInstance,
		loading,
	};
};

/** a provider that lets us show a download confirmation modal from anywhere */
const DownloadConfirmationContext = createContext<
	| {
			setDownloadCallback: React.Dispatch<
				React.SetStateAction<(() => Promise<void>) | undefined>
			>;
	  }
	| undefined
>(undefined);

export const DownloadConfirmationProvider = ({ children }: { children: ReactNode }) => {
	const [downloadCallback, setDownloadCallback] = useState<() => Promise<void>>();
	return (
		<DownloadConfirmationContext.Provider value={{ setDownloadCallback }}>
			{children}
			<ModalOrDrawer open={downloadCallback !== undefined}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Are you sure?"
					secondaryText="The file did not complete a virus scan. Do you want to download anyway?"
					confirmText="Download anyway"
					onSubmit={() => {
						if (downloadCallback) downloadCallback();
					}}
					onClose={() => setDownloadCallback(undefined)}
				/>
			</ModalOrDrawer>
		</DownloadConfirmationContext.Provider>
	);
};

export const useDownloadConfirmationContext = () => {
	const value = useContext(DownloadConfirmationContext);
	if (value === undefined)
		throw new Error(
			'useDownloadConfirmationContext must be used inside a DownloadConfirmationProvider'
		);
	return value;
};
