import { useEffect, useState } from 'react';
import {
	MESSAGE_ATTACHMENT_UPLOAD_PRIORITY,
	messageAttachmentUploadQueue,
	retryFileWithDelay,
	UploadState,
	useUpload,
} from 'utils/fileUtils';
import {
	FormMessageAttachment,
	NewMessageFormValues,
} from '../conversation-column/conversation-message-box';
import { useFormikContext } from 'formik';
import { useToast } from 'components/ui/toast';
import { v4 as uuid4 } from 'uuid';

export const useMessageAttachments = () => {
	const toast = useToast();
	const upload = useUpload();
	const { setFieldValue } = useFormikContext<NewMessageFormValues>();
	const [attachments, setAttachments] = useState<FormMessageAttachment[]>([]);

	// formik does not allow us to set a field value based on the previous value.
	// because the file uploading is async, we need to do this.
	// instead, we mantain a local copy of the files, and update formik with a useEffect.
	useEffect(() => {
		setFieldValue('messageAttachments', attachments);
	}, [attachments]);

	const setUploadStatus = (attachment: FormMessageAttachment, state: UploadState) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadState: state,
					};
				}
				return a;
			})
		);

	const resetUploadProgress = (attachment: FormMessageAttachment, numParts: number) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadProgress: new Array(numParts).fill(0),
					};
				}
				return a;
			})
		);

	const setUploadProgress = (
		attachment: FormMessageAttachment,
		progress: number,
		partNumber: number
	) =>
		setAttachments((prev) =>
			prev.map((a) => {
				let newValue = [...a.uploadProgress];
				newValue[partNumber - 1] = progress;
				if (a.key === attachment.key) {
					return {
						...a,
						uploadProgress: newValue,
					};
				}
				return a;
			})
		);

	const setUploadToken = (attachment: FormMessageAttachment, token: string) =>
		setAttachments((prev) =>
			prev.map((a) => {
				if (a.key === attachment.key) {
					return {
						...a,
						uploadToken: token,
					};
				}
				return a;
			})
		);

	const onDropAccepted = (files: File[]) => {
		const newAttachments: FormMessageAttachment[] = files.map((file) => {
			return {
				file: file,
				key: uuid4(),
				uploadState: UploadState.Pending,
				uploadProgress: new Array(1).fill(0),
			};
		});

		setAttachments((prev) => [...prev, ...newAttachments]);

		newAttachments.forEach((attachment) => {
			messageAttachmentUploadQueue.add(async () => {
				try {
					return await retryFileWithDelay(
						async () =>
							await upload({
								file: attachment.file,
								onUploadUrlFetched: (uploadToken) => {
									const blobUploadUrl = uploadToken?.blobUploadUrls;
									const fileUploadToken = uploadToken?.fileUploadToken;

									if (!blobUploadUrl || !fileUploadToken) {
										setUploadStatus(attachment, UploadState.Error);
										return;
									}

									setUploadToken(attachment, fileUploadToken);
									setUploadStatus(attachment, UploadState.Loading);
									resetUploadProgress(
										attachment,
										uploadToken.blobUploadUrls.length
									);
								},
								onUploadProgress: (e, partNumber) =>
									setUploadProgress(attachment, e.progress ?? 0, partNumber),
								onSuccess: async () =>
									setUploadStatus(attachment, UploadState.Success),
								onError: (e) => {
									toast.push(
										(e.graphQLErrors[0].extensions.userMessage as any) ??
											'An error occurred while trying to upload the file.',
										{
											variant: 'error',
										}
									);
									setUploadStatus(attachment, UploadState.Error);
								},
								priority: MESSAGE_ATTACHMENT_UPLOAD_PRIORITY,
							})
					);
				} catch (e) {
					toast.push('An error occurred while trying to upload the file.', {
						variant: 'error',
					});
					resetUploadProgress(attachment, 1);
					setUploadStatus(attachment, UploadState.Error);
				}
			});
		});
	};

	const onRemove = (key: string) => setAttachments((prev) => prev.filter((a) => a.key !== key));

	const clearAttachments = () => setAttachments([]);

	return { attachments, onDropAccepted, onRemove, clearAttachments };
};
