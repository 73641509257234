import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationUserProfileExperienceCompanyAndPositionCreateArgs,
	MutationUserProfileExperienceCompanyDeleteArgs,
	MutationUserProfileExperienceCompanyUpdateArgs,
	MutationUserProfileExperiencePositionCreateArgs,
	MutationUserProfileExperiencePositionDeleteArgs,
	MutationUserProfileExperiencePositionUpdateArgs,
	Query,
	QueryGetRecommendedLogoUrlArgs,
	QueryUserProfileExperienceGetArgs,
	UserExperienceCompanyUpdate,
	UserExperiencePositionUpdate,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

export type UserExperienceCompanyAndPositionCreation = {
	positionRequest: UserExperiencePositionUpdate;
	companyRequest: UserExperienceCompanyUpdate;
};

const USER_EXPERIENCE_POSITION_FIELDS = gql`
	fragment UserExperiencePositionFields on UserExperiencePosition {
		id
		userExperienceCompanyId
		employmentType
		locationType
		jobTitle
		location
		description
		startMonth
		startYear
		endMonth
		endYear
	}
`;

const USER_EXPERIENCE_COMPANY_FIELDS = gql`
	${USER_EXPERIENCE_POSITION_FIELDS}
	fragment UserExperienceCompanyFields on UserExperienceCompany {
		id
		userId
		name
		logoUrl
		organizationId
		websiteUrl
		positions {
			...UserExperiencePositionFields
		}
	}
`;

/** get profile */
const GET_USER_EXPERIENCE = gql`
	${USER_EXPERIENCE_COMPANY_FIELDS}
	query userProfileExperienceGet($userId: ID!) {
		userProfileExperienceGet(userId: $userId) {
			companies {
				...UserExperienceCompanyFields
			}
		}
	}
`;

/** add company */
const ADD_USER_EXPERIENCE_COMPANY_AND_POSITION = gql`
	${USER_EXPERIENCE_COMPANY_FIELDS}
	mutation userProfileExperienceCompanyAndPositionCreate(
		$userId: ID!
		$request: UserExperienceCompanyAndPositionCreation!
	) {
		userProfileExperienceCompanyAndPositionCreate(userId: $userId, request: $request) {
			...UserExperienceCompanyFields
		}
	}
`;

/** update company */
const UPDATE_USER_EXPERIENCE_COMPANY = gql`
	${USER_EXPERIENCE_COMPANY_FIELDS}
	mutation userProfileExperienceCompanyUpdate(
		$userId: ID!
		$userExperienceCompanyId: ID!
		$request: UserExperienceCompanyUpdate!
	) {
		userProfileExperienceCompanyUpdate(
			userId: $userId
			userExperienceCompanyId: $userExperienceCompanyId
			request: $request
		) {
			...UserExperienceCompanyFields
		}
	}
`;

/** delete company */
const DELETE_USER_EXPERIENCE_COMPANY = gql`
	mutation userProfileExperienceCompanyDelete($userId: ID!, $userExperienceCompanyId: ID!) {
		userProfileExperienceCompanyDelete(
			userId: $userId
			userExperienceCompanyId: $userExperienceCompanyId
		)
	}
`;

/** add position */
const ADD_USER_EXPERIENCE_POSITION = gql`
	${USER_EXPERIENCE_POSITION_FIELDS}
	mutation userProfileExperiencePositionCreate(
		$userId: ID!
		$userExperienceCompanyId: ID!
		$request: UserExperiencePositionUpdate!
	) {
		userProfileExperiencePositionCreate(
			userId: $userId
			userExperienceCompanyId: $userExperienceCompanyId
			request: $request
		) {
			...UserExperiencePositionFields
		}
	}
`;

/** update position */
const UPDATE_USER_EXPERIENCE_POSITION = gql`
	${USER_EXPERIENCE_POSITION_FIELDS}
	mutation userProfileExperiencePositionUpdate(
		$userId: ID!
		$userExperienceCompanyId: ID!
		$userExperiencePositionId: ID!
		$request: UserExperiencePositionUpdate!
	) {
		userProfileExperiencePositionUpdate(
			userId: $userId
			userExperienceCompanyId: $userExperienceCompanyId
			userExperiencePositionId: $userExperiencePositionId
			request: $request
		) {
			...UserExperiencePositionFields
		}
	}
`;

/** delete position */
const DELETE_USER_EXPERIENCE_POSITION = gql`
	mutation userProfileExperiencePositionDelete(
		$userId: ID!
		$userExperienceCompanyId: ID!
		$userExperiencePositionId: ID!
	) {
		userProfileExperiencePositionDelete(
			userId: $userId
			userExperienceCompanyId: $userExperienceCompanyId
			userExperiencePositionId: $userExperiencePositionId
		)
	}
`;

/** get recommended logo url */
const GET_RECOMMENDED_LOGO_URL = gql`
	query getRecommendedLogoUrl($websiteUrl: String!) {
		getRecommendedLogoUrl(websiteUrl: $websiteUrl) {
			logoUrl
		}
	}
`;

// Get experience
export const useUserProfileExperience = (userId: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'userProfileExperienceGet'>,
		QueryUserProfileExperienceGetArgs
	>(GET_USER_EXPERIENCE, {
		variables: { userId },
		onError: (e) => console.log(JSON.stringify(e)),
	});

	const companies = data?.userProfileExperienceGet.companies;
	return { companies, loading, error };
};

// Add a company (with a position)
export const useAddUserProfileCompanyAndPosition = (userId: string) => {
	const toast = useToast();
	const [_addUserExperienceCompanyAndPosition] = useMutation<
		Pick<Mutation, 'userProfileExperienceCompanyAndPositionCreate'>,
		MutationUserProfileExperienceCompanyAndPositionCreateArgs
	>(ADD_USER_EXPERIENCE_COMPANY_AND_POSITION);

	const addUserProfileCompanyAndPosition = async (
		request: UserExperienceCompanyAndPositionCreation
	) => {
		return await _addUserExperienceCompanyAndPosition({
			variables: {
				userId,
				request,
			},
			refetchQueries: [GET_USER_EXPERIENCE],
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('User experience added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return addUserProfileCompanyAndPosition;
};

// Update a company
export const useUpdateUserProfileExperienceCompany = (userId: string) => {
	const toast = useToast();
	const [_updateUserExperienceCompany] = useMutation<
		Pick<Mutation, 'userProfileExperienceCompanyUpdate'>,
		MutationUserProfileExperienceCompanyUpdateArgs
	>(UPDATE_USER_EXPERIENCE_COMPANY);

	const updateUserExperienceCompany = async (
		userExperienceCompanyId: string,
		request: UserExperienceCompanyUpdate
	) => {
		return await _updateUserExperienceCompany({
			variables: { userId, userExperienceCompanyId, request },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('User experience company updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateUserExperienceCompany;
};

// Delete a company
export const useDeleteUserProfileExperienceCompany = (
	userId: string,
	userExperienceCompanyId: string
) => {
	const toast = useToast();
	const [_deleteUserExperienceCompany] = useMutation<
		Pick<Mutation, 'userProfileExperienceCompanyDelete'>,
		MutationUserProfileExperienceCompanyDeleteArgs
	>(DELETE_USER_EXPERIENCE_COMPANY);

	const deleteUserExperienceCompany = async () => {
		return await _deleteUserExperienceCompany({
			variables: { userId, userExperienceCompanyId },
			refetchQueries: [GET_USER_EXPERIENCE],
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('User experience company deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return deleteUserExperienceCompany;
};

// Add a position
export const useAddUserProfileExperiencePositionAtExistingCompany = (
	userId: string,
	userExperienceCompanyId: string
) => {
	const toast = useToast();

	const [_addUserExperiencePosition] = useMutation<
		Pick<Mutation, 'userProfileExperiencePositionCreate'>,
		MutationUserProfileExperiencePositionCreateArgs
	>(ADD_USER_EXPERIENCE_POSITION);

	const addUserProfileExperiencePositionAtExistingCompany = async (
		request: UserExperiencePositionUpdate
	) => {
		return await _addUserExperiencePosition({
			variables: {
				userId,
				userExperienceCompanyId,
				request,
			},
			refetchQueries: [GET_USER_EXPERIENCE],
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('User experience added successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return addUserProfileExperiencePositionAtExistingCompany;
};

// Update a position
export const useUpdateUserProfileExperiencePosition = (
	userId: string,
	userExperienceCompanyId: string
) => {
	const toast = useToast();

	const [_updateUserExperiencePosition] = useMutation<
		Pick<Mutation, 'userProfileExperiencePositionUpdate'>,
		MutationUserProfileExperiencePositionUpdateArgs
	>(UPDATE_USER_EXPERIENCE_POSITION);

	const updateUserProfileExperiencePosition = async (
		userExperiencePositionId: string,
		request: UserExperiencePositionUpdate
	) => {
		return await _updateUserExperiencePosition({
			variables: {
				userId,
				userExperienceCompanyId,
				userExperiencePositionId,
				request,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Position updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateUserProfileExperiencePosition;
};

// Delete a position
export const useDeleteUserProfileExperiencePosition = (
	userId: string,
	userExperienceCompanyId: string,
	userExperiencePositionId: string
) => {
	const toast = useToast();

	const [_deleteUserExperiencePosition] = useMutation<
		Pick<Mutation, 'userProfileExperiencePositionDelete'>,
		MutationUserProfileExperiencePositionDeleteArgs
	>(DELETE_USER_EXPERIENCE_POSITION);

	const deleteUserExperiencePosition = async () => {
		return await _deleteUserExperiencePosition({
			variables: {
				userId,
				userExperienceCompanyId,
				userExperiencePositionId,
			},
			refetchQueries: [GET_USER_EXPERIENCE],
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Position deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return deleteUserExperiencePosition;
};

// Get recommended logo url
export const useGetRecommendedLogoUrl = (websiteUrl: string) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'getRecommendedLogoUrl'>,
		QueryGetRecommendedLogoUrlArgs
	>(GET_RECOMMENDED_LOGO_URL, { skip: !websiteUrl, variables: { websiteUrl } });

	const recommendedLogoUrl = data?.getRecommendedLogoUrl.logoUrl;

	return {
		recommendedLogoUrl,
		loading,
	};
};
