import { EditOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAnonymousSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { useProfileHeaderMutation, useProfileHeaderQuery } from './header/hooks';

interface UserAboutFormValues {
	about: string;
}

interface UserAboutCardProps {
	userId: string;
}

export const UserAboutCard = ({ userId }: UserAboutCardProps) => {
	const toast = useToast();

	const { user } = useAnonymousSession();
	const { hasPermission } = useSiteUser();
	const { profile, loading } = useProfileHeaderQuery(userId);
	const { updateProfile } = useProfileHeaderMutation(userId);

	const [editing, setEditing] = useState(false);
	const canEdit =
		(user && user.userId === userId) || hasPermission(Permission.SocialUser_Profile_U);

	const initialValues: UserAboutFormValues = {
		about: profile?.about ?? '',
	};

	const onSubmit = async (values: UserAboutFormValues) => {
		if (!profile) return;
		await updateProfile({
			about: values.about,
			companyName: profile.companyName,
			companyTitle: profile.companyTitle,
			languageIds: profile.languageIds ?? [],
			workCodes: profile.workCodes,
			xProfileLink: profile.xProfileLink,
			linkedinProfileLink: profile.linkedinProfileLink,
			facebookProfileLink: profile.facebookProfileLink,
			conversationInsuranceProfileLink: profile.conversationInsuranceProfileLink,
			name: {
				firstName: profile.name.firstName ?? '',
				lastName: profile.name.lastName ?? '',
				middleName: profile.name.middleName,
				suffix: profile.name.suffix,
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Profile updated successfully.', { variant: 'success' });
				setEditing(false);
			})
			.catch(() => {
				handleNoResponse({ toast });
			});
	};

	return (
		<Formik<UserAboutFormValues>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={onSubmit}>
			{({ submitForm, isSubmitting, dirty, resetForm }) => (
				<Card sx={{ borderRadius: { xs: 0, sm: 1 } }}>
					<Stack
						direction="row"
						px={{ xs: 2, sm: 2.5 }}
						py={1}
						alignItems="center"
						justifyContent="space-between">
						<Typography variant="h3">About</Typography>
						{editing ? (
							<Stack direction="row" spacing={1.5}>
								<Button variant="outlined" onClick={() => setEditing(false)}>
									Cancel
								</Button>
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={submitForm}
									disabled={!dirty}
									loading={isSubmitting}>
									Save
								</LoadingButton>
							</Stack>
						) : canEdit && !loading ? (
							<IconButton
								onClick={() => {
									resetForm();
									setEditing(true);
								}}>
								<EditOutlined />
							</IconButton>
						) : (
							<Box height={40} />
						)}
					</Stack>
					<Divider />
					<Box p={{ xs: 2, sm: 2.5 }}>
						{loading ? (
							<>
								<Skeleton variant="text" width="100%" />
								<Skeleton variant="text" width="100%" />
								<Skeleton variant="text" width="100%" />
								<Skeleton variant="text" width="60%" />
							</>
						) : editing ? (
							<TextField name="about" label="About" multiline />
						) : (
							<Typography>{profile?.about}</Typography>
						)}
					</Box>
				</Card>
			)}
		</Formik>
	);
};
