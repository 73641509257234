import {
	AssignmentOutlined,
	DeleteOutlined,
	EditOutlined,
	GetAppOutlined,
	MoreHorizOutlined,
	Subject,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Chip,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Typography,
} from '@mui/material';
import {
	useDeleteUsaAdjusterLicensesMutation,
	useUsaAdjusterLicensingManualMutation,
} from 'components/pages/user/profile/licensing/usa/hooks';
import { Alert } from 'components/ui/alert';
import { DatePickerField, NumberField, TextField } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { AdjusterLicensingSummary } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { UserProfileAspectType, usePrivacy } from 'utils/usePrivacy';
import { useValidation } from 'utils/useValidation';
import * as Yup from 'yup';
import { NpnModal } from './find-npn-modal';

interface AddUsaLicensingManualModalProps extends ModalContentProps {
	userId: string;
}

export const AddUsaLicensingManualModal = ({
	userId,
	onClose,
}: AddUsaLicensingManualModalProps) => {
	const navigate = useNavigate();
	const validation = useValidation('UsaAdjusterLicensing');
	const mutation = useUsaAdjusterLicensingManualMutation(userId);
	const toast = useToast();

	const validationSchema = Yup.object().shape({
		birthDate: Yup.date().nullable().typeError('Invalid date'),
	});

	type FormValues = {
		firstName: string;
		middleName: string;
		lastName: string;
		suffix: string;
		birthDate: Date | null;
		npn: number | undefined;
	};

	const onSubmit = async (values: FormValues) => {
		await mutation
			.updateLicenses({
				...values,
				npn: values.npn ?? 0,
				birthDate: values?.birthDate
					? format(values?.birthDate, 'yyyy-MM-dd')
					: '0001-01-01',
				statesLicensing: [],
			})
			.then((success) => {
				if (!success) return;
				toast.push('Successfully added licensing data.', {
					variant: 'success',
				});
				onClose();
				navigate(`license/usa`);
			});
	};

	return (
		<Formik<FormValues>
			initialValues={{
				firstName: '',
				middleName: '',
				lastName: '',
				suffix: '',
				birthDate: null,
				npn: undefined,
			}}
			validationSchema={validation.schema && validationSchema}
			onSubmit={onSubmit}>
			{(fprops) => (
				<>
					<ModalHeader title="Add Manual US Licensing Information" onClose={onClose} />
					<ModalContent>
						{mutation.error && <Alert error={mutation.error} />}
						<Box>
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="First Name"
											required
											name="firstName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="Middle Name"
											name="middleName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField
											label="Last Name"
											required
											name="lastName"
											fullWidth
										/>
									)}
								</Grid>
								<Grid xs={12} md={3} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<TextField label="Suffix" name="suffix" fullWidth />
									)}
								</Grid>
								<Grid xs={12} md={12} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<DatePickerField
											label="Date of Birth"
											required
											name="birthDate"
										/>
									)}
								</Grid>
								<Grid xs={12} md={12} item>
									{validation.loading ? (
										<Skeleton
											variant="rectangular"
											height={30}
											width={'100%'}
										/>
									) : (
										<NumberField
											required={true}
											name="npn"
											label="National Producer Number (NPN)"
											positiveNumber={true}
											integerOnly={true}
											maxLength={9}
										/>
									)}
								</Grid>
							</Grid>
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" disabled={fprops.isSubmitting} onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							color="primary"
							variant="contained"
							loading={fprops.isSubmitting}
							disabled={validation.loading || !fprops.isValid || !fprops.dirty}
							onClick={() => fprops.submitForm()}>
							Add Licenses
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

interface AddUsaLicensingModalProps extends ModalContentProps {
	userId: string;
	openManualModal: () => void;
	openNpnModal: () => void;
}

export const AddUsaLicensingModal = ({
	userId,
	openManualModal,
	openNpnModal,
	onClose,
}: AddUsaLicensingModalProps) => {
	const { hasAccess } = usePrivacy(userId);

	// Disable a coming soon feature
	// Remove this line when the feature is ready to be enabled
	const disabled = false;

	return (
		<>
			<ModalHeader title="Add US Licensing Information" onClose={onClose} />
			<ModalContent alignItems="center" textAlign="center" px={6} py={3} spacing={2}>
				<Box component="img" src="/img/temp-flag.svg" height={100} width={100} />
				<Typography variant="body1">
					&quot;How would you like to input your United States licensing
					information?&quot;
				</Typography>
			</ModalContent>
			<ModalButtonsContainer>
				<Button
					variant="outlined"
					disabled={!hasAccess(UserProfileAspectType.ImportLicensing) || disabled}
					onClick={() => {
						openNpnModal();
						onClose();
					}}
					startIcon={<GetAppOutlined />}
					endIcon={
						disabled ? (
							<Chip
								disabled
								label="COMING SOON"
								size="small"
								sx={{ '.MuiChip-label': { fontSize: 14 } }}
							/>
						) : undefined
					}>
					Import from NIPR
				</Button>
				<Button
					variant="outlined"
					startIcon={<EditOutlined />}
					onClick={() => {
						openManualModal();
						onClose();
					}}>
					Enter Manually
				</Button>
			</ModalButtonsContainer>
		</>
	);
};

/**
 * UsaContextOptions - Context menu options for a USA license.
 */
export const UsaContextOptions = ({ summary }: { summary: AdjusterLicensingSummary }) => {
	const navigate = useNavigate();
	const toast = useToast();
	const { hasAccess } = usePrivacy(summary.userId);

	const { deleteLicenses } = useDeleteUsaAdjusterLicensesMutation(summary.userId);
	const onDelete = async () =>
		await deleteLicenses()
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully deleted licensing data.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [quickViewOpen, setQuickViewOpen] = useState(false);
	const [editLicenseOpen, setEditLicenseOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [npnOpen, setNpnOpen] = useState(false);

	// If you don't have access to any licensing features, don't show the context menu at all.
	if (
		!hasAccess(
			UserProfileAspectType.ViewLicensingDetails,
			UserProfileAspectType.AlterLicensing,
			UserProfileAspectType.ImportLicensing
		)
	)
		return <></>;

	// Disable a comming soon feature
	// Remove this line when the feature is ready to be enabled
	const disabled = true;

	return (
		<>
			<IconButton onClick={(ev) => setAnchorEl(ev.currentTarget)}>
				<MoreHorizOutlined />
			</IconButton>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				onClick={() => setAnchorEl(null)}>
				{hasAccess(UserProfileAspectType.ViewLicensingDetails) && (
					<MenuItem onClick={() => setQuickViewOpen(true)}>
						<ListItemIcon>
							<Subject />
						</ListItemIcon>
						<ListItemText>Quick View</ListItemText>
					</MenuItem>
				)}
				{hasAccess(
					UserProfileAspectType.ViewLicensingDetails,
					UserProfileAspectType.AlterLicensing
				) && (
					<MenuItem
						onClick={() => {
							if (
								summary.licensingProvenance === 'Nipr' &&
								hasAccess(UserProfileAspectType.AlterLicensing)
							) {
								setEditLicenseOpen(true);
							} else {
								navigate(`license/usa`);
							}
						}}>
						<ListItemIcon>
							<AssignmentOutlined />
						</ListItemIcon>
						<ListItemText>License Details</ListItemText>
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.ImportLicensing) && (
					<MenuItem onClick={() => setNpnOpen(true)} disabled={disabled}>
						<ListItemIcon>
							<GetAppOutlined />
						</ListItemIcon>
						<ListItemText>
							{summary.licensingProvenance === 'Nipr'
								? 'Reimport from NIPR'
								: 'Import From NIPR'}
						</ListItemText>
						{disabled && (
							<Chip sx={{ marginLeft: 1 }} label="COMING SOON" size="small" />
						)}
					</MenuItem>
				)}
				{hasAccess(UserProfileAspectType.AlterLicensing) && (
					<MenuItem onClick={() => setDeleteOpen(true)}>
						<ListItemIcon>
							<DeleteOutlined />
						</ListItemIcon>
						<ListItemText>Delete Country</ListItemText>
					</MenuItem>
				)}
			</Menu>
			{/** quick view modal */}
			<ModalOrDrawer open={quickViewOpen} dialogProps={{ maxWidth: 'lg' }}>
				<ModalHeader
					title="Quick View: Licensing"
					onClose={() => setQuickViewOpen(false)}
				/>
				<ModalContent>
					<Typography component="pre" fontFamily="monospace">
						{summary.adjusterLicensingText}
					</Typography>
				</ModalContent>
				<ModalButtonsContainer>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setQuickViewOpen(false)}>
						Close
					</Button>
				</ModalButtonsContainer>
			</ModalOrDrawer>
			{/** edit license modal */}
			<ModalOrDrawer open={editLicenseOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="NIPR verification can be lost"
					secondaryText="Your licensing information is currently verified by NIPR. Any changes made to your licensing information manually will make your information unverified."
					confirmText="Continue"
					onSubmit={() => navigate('license/usa')}
					onClose={() => setEditLicenseOpen(false)}
				/>
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Delete United States licensing?"
					secondaryText="Do you really want to delete the licensing data for this country? This process cannot be undone."
					onSubmit={onDelete}
					onClose={() => setDeleteOpen(false)}
				/>
			</ModalOrDrawer>
			{/** npn modal */}
			<ModalOrDrawer open={npnOpen}>
				<NpnModal userId={summary.userId} onClose={() => setNpnOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};
