import { ApolloError } from '@apollo/client';
import { Box, Link, Stack, Typography } from '@mui/material';
import {
	AccessLevelChip,
	AccessLevelIcon,
} from 'components/pages/documents/components/access-level';
import { UploadTokenResponse } from 'middleware-types';
import { useEffect, useRef, useState } from 'react';
import {
	DOCUMENT_UPLOAD_PRIORITY,
	ExtensionIcon,
	UploadState,
	documentUploadQueue,
	getUploadProgress,
	retryFileWithDelay,
	useUpload,
} from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import { DocumentUploadSpinner } from './upload-status-display';
import { UploadsDrawerItem } from './uploads-drawer';

interface UploadsDrawerRowProps {
	item: UploadsDrawerItem;
	setState: (value: UploadState) => void;
}

export const UploadsDrawerRow = ({ item, setState }: UploadsDrawerRowProps) => {
	const isMobile = useIsMobile();
	const [uploadProgress, setUploadProgress] = useState<number[]>(new Array(1).fill(0));
	const [errorMessage, setErrorMessage] = useState('An error occurred.');
	const uploading = useRef(false);
	const upload = useUpload();

	const setApolloErrorMessage = (e: ApolloError) => {
		if (e.graphQLErrors.length > 0) {
			setErrorMessage(e.graphQLErrors[0].extensions.userMessage as string);
		} else {
			setErrorMessage(e.message);
		}
	};

	useEffect(() => {
		if (uploading.current) return;
		uploading.current = true;

		documentUploadQueue.add(async () => {
			try {
				return await retryFileWithDelay(
					async () =>
						await upload({
							file: item.file,
							accessLevel: item.accessLevel,
							updatesFileId: item.updatesFileId,
							onUploadUrlFetched: (uploadToken) => {
								setState(UploadState.Loading);
								setUploadProgress(
									new Array(uploadToken.blobUploadUrls.length).fill(0)
								);
							},
							onUploadProgress: (e, partNumber) =>
								setUploadProgress((oldValue) => {
									let newValue = [...oldValue];
									newValue[partNumber - 1] = e.progress ?? 0;
									return newValue;
								}),
							onSuccess: async (uploadTokenResponse?: UploadTokenResponse) => {
								if (!uploadTokenResponse) return;

								try {
									const success = await item.onCompleted(
										uploadTokenResponse.fileUploadToken
									);

									if (success) {
										setState(UploadState.Success);
									} else {
										setUploadProgress(new Array(1).fill(0));
										setState(UploadState.Error);
									}
								} catch (e) {
									setUploadProgress(new Array(1).fill(0));
									setState(UploadState.Error);
								}
							},
							onError: (e) => {
								setApolloErrorMessage(e);
								setState(UploadState.Error);
							},
							priority: DOCUMENT_UPLOAD_PRIORITY,
						})
				);
			} catch (e) {
				if (e instanceof ApolloError) {
					setApolloErrorMessage(e);
				}
				setUploadProgress(new Array(1).fill(0));
				setState(UploadState.Error);
			}
		});
	}, []);

	return (
		<Stack direction="row" alignItems="center" px={2} py={1} spacing={2}>
			<Stack flex={1} direction="row" alignItems="center" spacing={1.5} overflow="hidden">
				<ExtensionIcon filename={item.file.name} sx={{ color: 'neutral.500' }} />
				<Stack flex={1} overflow="hidden">
					<Typography variant="body1" noWrap textOverflow="ellipsis">
						{item.file.name}
					</Typography>
					{item.state === UploadState.Error ? (
						<Typography variant="caption" color="error">
							{errorMessage}
						</Typography>
					) : (
						<Link
							href={item.url}
							variant="caption"
							color="GrayText"
							maxWidth="100%"
							noWrap>
							{item.subtitle}
						</Link>
					)}
				</Stack>
			</Stack>
			{item.state !== UploadState.Error &&
				(isMobile ? (
					<AccessLevelIcon level={item.accessLevel} sx={{ color: 'neutral.500' }} />
				) : (
					<Box width="20%">
						<AccessLevelChip level={item.accessLevel} />
					</Box>
				))}
			<Stack flex={isMobile ? undefined : 0.25} alignItems="flex-end">
				<DocumentUploadSpinner
					size={24}
					state={item.state}
					progress={getUploadProgress(uploadProgress)}
					showPercent={!isMobile}
				/>
			</Stack>
		</Stack>
	);
};
