import { CheckOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	Badge,
	Box,
	Button,
	ButtonBase,
	TextField as MuiTextField,
	Stack,
	Typography,
} from '@mui/material';
import { useQuickSearchQuery } from 'components/pages/quick-search';
import { useEmblem } from 'components/ui/emblem/hooks';
import { Formik, FormikErrors, FormikProps, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import {
	EmblemEntityType,
	EntitySearchMode,
	UserExperienceCompany,
	UserExperienceCompanyUpdate,
} from 'middleware-types';
import { useCallback, useMemo, useState } from 'react';
import { useValidation } from 'utils/useValidation';
import { useGetRecommendedLogoUrl, useUpdateUserProfileExperienceCompany } from './hooks';
import { getLogoPermutations } from './user-experience-logo-link';

const urlRegex =
	/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const validateCompany = (values: UserExperienceCompanyUpdate) => {
	const errors: FormikErrors<UserExperienceCompanyUpdate> = {};
	if (!values.organizationId && !values.name)
		errors.name = 'Company name required if pre-existing organization is not selected';
	return errors;
};

interface UserExperienceCompanyFormProps {
	userId: string;
	company: UserExperienceCompany;
	onClose: () => void;
}

export const UserExperienceCompanyForm = ({
	userId,
	company,
	onClose,
}: UserExperienceCompanyFormProps) => {
	const updateUserProfileExperienceCompany = useUpdateUserProfileExperienceCompany(userId);

	const onSubmit = async (input: UserExperienceCompanyUpdate) => {
		const request = {
			name: input.name,
			logoUrl: input.logoUrl,
			organizationId: input.organizationId,
			websiteUrl: input.logoUrl ? input.websiteUrl : '',
		} as UserExperienceCompanyUpdate;

		const success = await updateUserProfileExperienceCompany(company.id, request);
		if (success) onClose();
	};

	const initialValues: UserExperienceCompanyUpdate = {
		name: company?.name ?? '',
		logoUrl: company?.logoUrl ?? '',
		organizationId: company?.organizationId ?? '',
		websiteUrl: company?.websiteUrl ?? '',
	};

	const validation = useValidation('UserExperienceCompanyUpdate');

	return (
		<Formik<UserExperienceCompanyUpdate>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={validation.schema}
			validate={validateCompany}
			enableReinitialize>
			{(props: FormikProps<UserExperienceCompanyUpdate>) => (
				<Stack spacing={2}>
					<UserExperienceCompanyFields />
					<Stack direction="row" justifyContent="flex-end" spacing={1}>
						<LoadingButton
							loading={props.isSubmitting}
							variant="outlined"
							onClick={onClose}>
							Cancel
						</LoadingButton>
						<LoadingButton
							loading={props.isSubmitting}
							variant="contained"
							color="primary"
							disabled={!props.dirty || props.isSubmitting || !props.isValid}
							onClick={() => {
								props.submitForm();
							}}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};

const RESULTS_LIMIT = 10;
const DEBOUNCE_TIMER_IN_MS = 500;

interface InputValueType {
	orgId: string;
	displayName: string;
	inputValue?: string;
	disabled?: boolean;
}

export const UserExperienceCompanyFields = () => {
	const { values, setFieldValue } = useFormikContext<UserExperienceCompanyUpdate>();

	const { quickSearch, results, loading } = useQuickSearchQuery();
	const debouncedSearch = useCallback(
		debounce((searchText) => {
			if (!searchText) return;
			quickSearch(searchText, RESULTS_LIMIT, EntitySearchMode.Organization);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	const showManualEntryFields = Boolean(values.name);

	// map orgs to proper type
	const options: InputValueType[] = results.map((o) => ({
		orgId: o.id,
		displayName: o.displayName,
	}));

	// get the emblem of the currently selected org and add it to options
	const selectedOrgId = values.organizationId ?? '';
	const { emblem } = useEmblem(selectedOrgId, EmblemEntityType.Organization, !selectedOrgId);
	if (selectedOrgId && emblem && !options.some((option) => option.orgId === selectedOrgId))
		options.push({ orgId: selectedOrgId, displayName: emblem.displayName });

	// if the value is not memoized, it will change every render, and overwrite our text field
	const value = useMemo(() => {
		return (
			options.find((option) => option.orgId === selectedOrgId) ??
			(values.name ? { orgId: '', displayName: values.name } : null)
		);
	}, [values.organizationId, values.name]);

	// logo search stuff
	const [logoSearchFieldValue, setLogoSearchFieldValue] = useState('');
	const [currentLogoSearchString, setCurrentLogoSearchString] = useState('');

	const { recommendedLogoUrl } = useGetRecommendedLogoUrl(currentLogoSearchString);
	const logoUrls = currentLogoSearchString ? getLogoPermutations(currentLogoSearchString) : [];

	const logoSearchValid = urlRegex.test(logoSearchFieldValue);
	const [logoSearchTouched, setLogoSearchTouched] = useState(false);
	const logoSearchError =
		logoSearchTouched && !logoSearchValid ? 'Logo must be searched for by website URL' : '';

	const onLogoSearch = () => {
		setLogoSearchTouched(true);
		if (!logoSearchValid) return;

		const noHttp = logoSearchFieldValue.replace(/^https?:\/\//i, '');
		const cleanedUrl = noHttp.endsWith('/') ? noHttp.slice(0, -1) : noHttp;
		setLogoSearchFieldValue(cleanedUrl);
		setCurrentLogoSearchString(cleanedUrl);
	};

	const selectLogo = async (value: string) => {
		await setFieldValue('websiteUrl', currentLogoSearchString, false);
		await setFieldValue('logoUrl', value, true);
	};

	return (
		<Stack spacing={2}>
			<Autocomplete
				freeSolo
				selectOnFocus
				clearOnBlur
				blurOnSelect
				handleHomeEndKeys
				options={options}
				value={value}
				onInputChange={(_, newValue) => {
					debouncedSearch(newValue);
				}}
				getOptionLabel={(option) => {
					if (typeof option === 'string') return option;
					return option.displayName;
				}}
				isOptionEqualToValue={(option, value) => {
					return (
						Boolean(option.orgId) &&
						Boolean(value.orgId) &&
						option.orgId === value.orgId
					);
				}}
				getOptionDisabled={(option) => option.disabled ?? false}
				filterOptions={(options, { inputValue }) => {
					if (inputValue) {
						options.unshift({
							orgId: '',
							displayName: 'Click here or hit Enter to input this name manually',
							inputValue: inputValue,
						});
					}
					if (loading) {
						options.push({
							orgId: '',
							displayName: 'Organizations loading...',
							disabled: true,
						});
					}
					return options;
				}}
				onChange={async (_, newValue) => {
					// only validate on the last setFieldValue call
					if (newValue === null) {
						await setFieldValue('organizationId', '', false);
						await setFieldValue('name', '', false);
						await setFieldValue('websiteUrl', '', false);
						await setFieldValue('logoUrl', '', true);
					} else if (typeof newValue === 'string') {
						await setFieldValue('organizationId', '', false);
						await setFieldValue('name', newValue, true);
					} else if (newValue.inputValue) {
						await setFieldValue('organizationId', '', false);
						await setFieldValue('name', newValue.inputValue, true);
					} else {
						await setFieldValue('organizationId', newValue.orgId, false);
						await setFieldValue('name', '', false);
						await setFieldValue('websiteUrl', '', false);
						await setFieldValue('logoUrl', '', true);
					}
				}}
				renderInput={(params: AutocompleteRenderInputParams) => (
					<MuiTextField
						{...params}
						required
						label="Company"
						variant="outlined"
						sx={{ margin: 0 }}
					/>
				)}
			/>
			{showManualEntryFields && (
				<Stack direction="row" alignItems="flex-start" spacing={1.5}>
					<MuiTextField
						name="websiteUrl"
						label="Logo Search"
						placeholder="statefarm.com"
						value={logoSearchFieldValue}
						onChange={(e) => setLogoSearchFieldValue(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') onLogoSearch();
						}}
						error={Boolean(logoSearchError)}
						helperText={
							logoSearchError ||
							"Your company doesn't exist on Evolve yet. Enter your company's URL to do a logo search."
						}
					/>
					<Button
						variant="contained"
						color="primary"
						sx={{ height: 40, flexShrink: 0 }}
						onClick={onLogoSearch}>
						Search Logos
					</Button>
				</Stack>
			)}
			{showManualEntryFields && (
				<Stack
					direction="row"
					alignItems="flex-start"
					flexWrap="wrap"
					rowGap={4}
					columnGap={8}
					overflow="hidden">
					<Stack spacing={1} alignItems="flex-start">
						<Typography variant="h5">Currently selected logo:</Typography>
						{values.logoUrl ? (
							<Box
								component="img"
								src={values.logoUrl}
								height={100}
								width={100}
								borderRadius={2}
								sx={{
									objectFit: 'contain',
								}}
							/>
						) : (
							<Typography variant="body1" color="neutral.500">
								None
							</Typography>
						)}
						{values.logoUrl && (
							<Button
								variant="outlined"
								onClick={async () => {
									await setFieldValue('websiteUrl', '', false);
									await setFieldValue('logoUrl', '', true);
								}}>
								Clear selection
							</Button>
						)}
					</Stack>
					{logoUrls.length > 0 && (
						<Stack
							spacing={1}
							pl={4}
							ml={-4}
							borderLeft="1px solid"
							borderColor="divider">
							<Typography variant="h5">Select a logo:</Typography>
							<Stack direction="row" gap={2} alignItems="flex-start" flexWrap="wrap">
								{logoUrls.map((url) => (
									<LogoDisplayItem
										key={url}
										url={url}
										selected={url === values.logoUrl}
										recommended={url === recommendedLogoUrl}
										selectLogo={selectLogo}
									/>
								))}
							</Stack>
						</Stack>
					)}
				</Stack>
			)}
		</Stack>
	);
};

interface LogoDisplayItemProps {
	url: string;
	selected: boolean;
	recommended: boolean;
	selectLogo: (value: string) => void;
}

const LogoDisplayItem = ({ url, selected, recommended, selectLogo }: LogoDisplayItemProps) => {
	return (
		<Stack alignItems="center" spacing={0.5}>
			<Badge
				color="primary"
				badgeContent={<CheckOutlined />}
				invisible={!selected}
				sx={{
					'& .MuiBadge-badge': {
						height: '1.75rem',
						width: '1.75rem',
						borderRadius: '50%',
					},
				}}>
				<ButtonBase onClick={() => selectLogo(url)}>
					<Box
						component="img"
						src={url}
						height={100}
						width={100}
						borderRadius={2}
						border="2px solid"
						borderColor={selected ? 'primary.main' : 'transparent'}
						sx={{
							objectFit: 'contain',
						}}
					/>
				</ButtonBase>
			</Badge>
			{recommended && <Typography variant="caption">Recommended</Typography>}
		</Stack>
	);
};
