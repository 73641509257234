import { Container, Stack } from '@mui/material';
import { UserProfileOrgConnectionsBar } from 'components/pages/network/org/OrgConnections/orgConnectionsBarWrappers';
import { ProfileAdministration } from 'components/pages/user/profile/admin';
import { ProfileHeader } from 'components/pages/user/profile/header/header';
import { LicensingInformation } from 'components/pages/user/profile/licensing/licensing-info';
import { PageContent, PageTitle } from 'components/ui/page';
import { useAnonymousSession } from 'utils/session';
import { useSearchParam } from 'utils/useSearchParam';
import { UserExperienceCard } from './user-experience/user-experience-card';
import { UserAboutCard } from 'components/pages/user/profile/about';

export const UserProfilePage = (props: { userId: string }) => {
	const { anonymous } = useAnonymousSession();
	const [selectedTab] = useSearchParam('tab');

	return (
		<>
			<PageTitle title="Profile" />
			<PageContent mobileNoPadding>
				<Container maxWidth="md" disableGutters>
					<Stack spacing={{ xs: 2, md: 3 }}>
						<ProfileHeader userId={props.userId} />
						<ProfileAdministration />
						{!anonymous && <UserProfileOrgConnectionsBar userId={props.userId} />}
						{selectedTab === null && (
							<>
								<UserAboutCard userId={props.userId} />
								<UserExperienceCard userId={props.userId} />
							</>
						)}
						{selectedTab === 'licenses' && (
							<LicensingInformation userId={props.userId} />
						)}
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};
