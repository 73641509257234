export const getLogoPermutations = (query: string) => {
	return [
		`https://cdn.brandfetch.io/${query}?c=1id6lphFdk_1m-sCe7p`,
		`https://cdn.brandfetch.io/${query}/logo?c=1id6lphFdk_1m-sCe7p`,
		`https://cdn.brandfetch.io/${query}/symbol?c=1id6lphFdk_1m-sCe7p`,
		// Dark mode pending:
		// {
		// 	url: `https://cdn.brandfetch.io/${query}/theme/light?c=1id6lphFdk_1m-sCe7p`,
		// },
		// {
		// 	url: `https://cdn.brandfetch.io/${query}/theme/light/logo?c=1id6lphFdk_1m-sCe7p`,
		// },
		// {
		// 	url: `https://cdn.brandfetch.io/${query}/theme/light/symbol?c=1id6lphFdk_1m-sCe7p`,
		// },
	];
};
