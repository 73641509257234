import { MoreHoriz } from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useSkipUntilIntersecting } from 'utils/useSkipUntilIntersecting';
import { AddUpdateQuickLinkModal, DeleteQuickLinkModal } from './quickLinkModals';
import { ExtendedQuickLink, QuickLinksMethods } from './quickLinks';
import { useQuickLinkIconUrl } from './sharedHooks';

export const useQuickLinkSrc = (quickLink: ExtendedQuickLink, skip = false) => {
	const iconUrl = useQuickLinkIconUrl(quickLink, skip);

	const isInternalLink =
		new URL(quickLink.url).hostname.replace('www.', '') ===
		window.location.hostname.replace('www.', '');

	const src =
		quickLink.presetIcon ??
		iconUrl ??
		(isInternalLink
			? '/favicon.ico'
			: `https://www.google.com/s2/favicons?domain=${quickLink.url}&sz=64`);

	return src;
};

interface QuickLinkProps {
	methods: QuickLinksMethods;
	quickLink: ExtendedQuickLink;
	canEdit: boolean;
}

export const QuickLinkCard = ({ methods, quickLink, canEdit }: QuickLinkProps) => {
	const isMobile = useIsMobile();

	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [hovered, setHovered] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

	const { ref, skip } = useSkipUntilIntersecting<HTMLDivElement>();

	const src = useQuickLinkSrc(quickLink, skip);

	return (
		<>
			<Card
				ref={ref}
				sx={
					quickLink.isPresetLink
						? {
								borderWidth: '0 0 0 4px',
								borderColor: 'secondary.main',
								borderStyle: 'solid',
						  }
						: undefined
				}>
				<CardActionArea
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					component="a"
					href={quickLink.url}
					target="_blank"
					sx={{ height: '100%' }}>
					<CardContent
						sx={{
							height: '100%',
							paddingLeft: quickLink.isPresetLink ? '12px' : undefined,
						}}>
						<Stack direction="row" spacing={1} alignItems="center" height="100%">
							<img src={src} style={{ width: 48, height: 48 }} />
							<Box flex={1} overflow="hidden">
								<Typography variant="h4" noWrap>
									{quickLink.title}
								</Typography>
								<Typography variant="subtitle2" color="GrayText" noWrap>
									{quickLink.url.replace(/^https?:\/\/(www\.)?/, '')}
								</Typography>
							</Box>
							{(hovered || isMobile) && canEdit && !quickLink.isPresetLink && (
								<>
									<IconButton
										onMouseDown={(e) => e.stopPropagation()}
										onTouchStart={(e) => e.stopPropagation()}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setMenuAnchor(e.currentTarget);
										}}>
										<MoreHoriz />
									</IconButton>
									<Menu
										open={Boolean(menuAnchor)}
										anchorEl={menuAnchor}
										onMouseDown={(e) => e.stopPropagation()}
										onTouchStart={(e) => e.stopPropagation()}
										onClick={() => setMenuAnchor(null)}
										onClose={() => {
											setMenuAnchor(null);
											setHovered(false);
										}}>
										<MenuItem onClick={() => setEditModalOpen(true)}>
											<ListItemText primary="Edit Link" />
										</MenuItem>
										<MenuItem onClick={() => setDeleteModalOpen(true)}>
											<ListItemText primary="Delete Link" />
										</MenuItem>
									</Menu>
								</>
							)}
						</Stack>
					</CardContent>
				</CardActionArea>
			</Card>
			{/** edit modal */}
			<ModalOrDrawer open={editModalOpen}>
				<AddUpdateQuickLinkModal
					methods={methods}
					quickLink={quickLink}
					onClose={() => setEditModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<DeleteQuickLinkModal
					methods={methods}
					quickLinkId={quickLink.id}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
