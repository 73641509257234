import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { FileField } from 'components/ui/fields';
import { FileType, FileValue } from 'components/ui/fields/file';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { gql } from 'graphql-tag';
import { FileInstanceInformation } from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { ModalButtonsContainer } from '../modals/modal-buttons-container';
import { ModalContent } from '../modals/modal-content';
import { ModalHeader } from '../modals/modal-header';
import { ModalContentProps } from '../modals/modal-types';

export interface FileFieldUploadValues extends Omit<FileValue, 'uploadToken'> {
	uploadToken: string;
}

/**
 * useOrganizationAvatarOrBannerMutation() - Hook to update the org avatar
 */
export const useOrganizationAvatarOrBannerMutation = (organizationId: string) => {
	const [updateOrganizationAvatarOrBannerMutation, { loading, error }] =
		useMutation<FileFieldUploadValues>(
			gql`
				mutation organizationAvatarOrBannerUpdate(
					$organizationId: ID!
					$avatarUploadToken: String
					$bannerUploadToken: String
				) {
					organizationAvatarOrBannerUpdate(
						organizationId: $organizationId
						avatarUploadToken: $avatarUploadToken
						bannerUploadToken: $bannerUploadToken
					) {
						id
						avatarFile {
							fileId
							file {
								id
								currentInstance {
									id
									cdnUrl
									fileName
									fileSize
									virusStatus
									uploadedUtc
								}
							}
						}
						bannerFile {
							fileId
							file {
								id
								currentInstance {
									id
									cdnUrl
									fileName
									fileSize
									virusStatus
									uploadedUtc
								}
							}
						}
					}
				}
			`,
			{
				update: (cache, res, ctx) => {
					const fieldName = ctx.variables?.avatarUploadToken ? 'avatar' : 'banner';

					// Updating the profile picture can change the picture on account, profile, search, and emblem.
					cache.evict({
						id: `OrganizationAccount:${organizationId}`,
						fieldName,
					});
					cache.evict({
						id: `OrganizationEmblem:${organizationId}`,
						fieldName,
					});
					cache.evict({
						id: `QuickSearchEntity:${organizationId}`,
						fieldName: 'displayPicture',
					});

					cache.gc();
				},
			}
		);

	const updateOrganizationAvatarOrBanner = async (
		uploadToken: string,
		type: 'avatar' | 'banner'
	) =>
		updateOrganizationAvatarOrBannerMutation({
			variables: {
				organizationId,
				avatarUploadToken: type === 'avatar' ? uploadToken : null,
				bannerUploadToken: type === 'banner' ? uploadToken : null,
			},
		});

	return { updateOrganizationAvatarOrBanner, loading, error };
};

interface OrganizationEmblemUploaderModalProps extends ModalContentProps {
	organizationId: string;
	fileInstance?: FileInstanceInformation;
	type: 'avatar' | 'banner';
}

export const OrganizationEmblemUploaderModal = ({
	onClose,
	organizationId,
	fileInstance,
	type,
}: OrganizationEmblemUploaderModalProps) => {
	const toast = useToast();
	const { updateOrganizationAvatarOrBanner, error } =
		useOrganizationAvatarOrBannerMutation(organizationId);

	const [isUploading, setIsUploading] = useState(false);

	return (
		<Formik<{ organizationAvatarOrBanner: FileFieldUploadValues }>
			initialValues={{
				organizationAvatarOrBanner: {
					uploadToken: '',
					id: fileInstance?.id,
					fileName: fileInstance?.fileName,
					fileSize: fileInstance?.fileSize,
					uploadedUtc: fileInstance?.uploadedUtc,
					virusStatus: fileInstance?.virusStatus,
				},
			}}
			onSubmit={(values: { organizationAvatarOrBanner: FileFieldUploadValues }) => {
				return updateOrganizationAvatarOrBanner(
					values.organizationAvatarOrBanner.uploadToken,
					type
				)
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							console.log(res.errors);
							return false;
						}
						toast.push(
							`Organization ${
								type === 'avatar' ? 'Logo' : 'Banner'
							} updated successfully. It may take a few minutes for your logo to appear.`,
							{
								variant: 'success',
							}
						);
						onClose();
					})
					.catch((err) => {
						console.log(err);
						handleNoResponse({ toast });
					});
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalHeader
						title={`Upload Organization ${type === 'avatar' ? 'Logo' : 'Banner'}`}
						onClose={onClose}
					/>
					<ModalContent>
						<Alert error={error} />
						<Typography variant="h3" style={{ marginTop: 0 }}>
							Drag & drop an image below or click to upload
						</Typography>
						<FileField
							name="organizationAvatarOrBanner"
							type={FileType.Image}
							updatesFileId={fileInstance?.fileId}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							onClick={submitForm}
							disabled={!isValid || !dirty || isSubmitting || isUploading}
							loading={isSubmitting || isUploading}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
