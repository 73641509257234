import { BusinessOutlined } from '@mui/icons-material';
import { Avatar, Stack, Typography } from '@mui/material';
import { useEmblem } from 'components/ui/emblem/hooks';
import {
	EmblemEntityType,
	EmploymentLocationType,
	EmploymentType,
	UserExperienceCompany,
} from 'middleware-types';
import {
	formatWorkPeriod,
	getCompanyEndDate,
	getCompanyStartDate,
	pascalCaseAddSpaces,
} from './helpers';

interface ExperienceCompanyDisplayProps {
	company: UserExperienceCompany;
	allSameEmploymentType: EmploymentType | undefined;
	allSameLocation: string | undefined;
	allSameLocationType: EmploymentLocationType | undefined;
}

export const ExperienceCompanyDisplay = ({
	company,
	allSameEmploymentType,
	allSameLocation,
	allSameLocationType,
}: ExperienceCompanyDisplayProps) => {
	// skip emblem query if orgId is not present
	const orgId = company.organizationId ?? '';
	const { emblem } = useEmblem(orgId, EmblemEntityType.Organization, !orgId);

	const startDate = getCompanyStartDate(company);
	const endDate = getCompanyEndDate(company);
	const timeString = formatWorkPeriod(startDate, endDate);

	return (
		<Stack spacing={0.5}>
			<Typography variant="h4" lineHeight="normal">
				{emblem?.displayName ?? company.name}
			</Typography>
			<Typography variant="body1" lineHeight="normal">
				{allSameEmploymentType ? `${pascalCaseAddSpaces(allSameEmploymentType)} • ` : ''}
				{timeString}
			</Typography>
			<LocationAndLocationTypeDisplay
				location={allSameLocation}
				locationType={allSameLocationType}
			/>
		</Stack>
	);
};

export const LocationAndLocationTypeDisplay = ({
	location,
	locationType,
}: {
	location?: string;
	locationType?: EmploymentLocationType;
}) => {
	if (!location && !locationType) return <></>;
	return (
		<Typography variant="subtitle1" lineHeight="normal">
			{location}
			{location && locationType ? ' • ' : ''}
			{pascalCaseAddSpaces(locationType ?? '')}
		</Typography>
	);
};

export const ImageDisplayAvatar = ({ src, size = 40 }: { src?: string; size?: number }) => {
	return (
		<Avatar
			src={src}
			variant="rounded"
			sx={{
				'bgcolor': src ? 'white' : undefined,
				'height': size,
				'width': size,
				'fontSize': size / 2,
				'& img': { objectFit: 'contain' },
			}}>
			<BusinessOutlined sx={{ fontSize: '1.25em' }} />
		</Avatar>
	);
};
